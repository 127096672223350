<template>
  <section class="cont">
     <!-- 面包屑  -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>营销云</el-breadcrumb-item>
           <el-breadcrumb-item>运营</el-breadcrumb-item>
           <el-breadcrumb-item to="/activity_list/WEB">web弹窗</el-breadcrumb-item>
           <el-breadcrumb-item>web弹窗</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button  class="bg-gradient">保存草稿</el-button>
     </el-row>
     <!-- 返回页头 -->
     <el-page-header @back="goBack" content="创建Web弹窗"></el-page-header>
     <el-row class="cont-form-box">
        <!-- 步骤条 -->
           <el-steps :active="active" simple>
             <el-step title="1.设置活动信息"></el-step>
             <el-step title="2.目标用户"></el-step>
             <el-step title="3.如何触达人群"></el-step>
             <el-step title="4.发送什么内容"></el-step>
             <el-step title="5.预览"></el-step>
           </el-steps>
       <el-row class="content">
         <!-- 第一步 -->
         <el-form :model="contentForm" :rules="rules" ref="contentForm_0" label-width="100px">
           <el-row class="step1-cont" v-if="active === 0">
             <el-row>
               <el-form-item prop="eventName" label="活动名称">
                 <el-col :span="9" class="m-left-10">
                   <el-input  class="width-300" v-model="contentForm.eventName"></el-input>
                 </el-col>
               </el-form-item>
             </el-row>
             <el-row>
               <el-form-item prop="timeCycle" label="活动周期">
                 <el-col :span="9" class="m-left-10">
                   <el-date-picker class="width-300"  v-model="contentForm.timeCycle" value-format="yyyy-MM-dd" type="daterange" :start-placeholder="$t('msg.start_date')" :range-separator="$t('msg.to')" :end-placeholder="$t('msg.end_date')"></el-date-picker>
                 </el-col>
               </el-form-item>
             </el-row>
             <el-row>
               <el-form-item label="转化跟踪">
                 <el-col :span="9" class="m-left-10">
                   <el-switch v-model="contentForm.isConvert" active-color="#1489e2" inactive-color="#c5ced8"></el-switch>
                 </el-col>
               </el-form-item>
             </el-row>
             <el-row v-if="contentForm.isConvert">
               <el-form-item prop="targetId" label="转化目标">
                 <el-col :span="9" class="m-left-10">
                   <el-select  class="width-300" v-model="contentForm.targetId" placeholder="请选择">
                     <el-option v-for="item in targetList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                   </el-select>
                 </el-col>
               </el-form-item>
             </el-row>
             <el-row v-if="contentForm.isConvert">
               <el-form-item prop="days" label="转化周期">
                 <el-col :span="9" class="m-left-10">
                   <el-input v-model.number="contentForm.days"  class="width-140"></el-input>&nbsp;天内
                 </el-col>
               </el-form-item>
             </el-row>
           </el-row>
         </el-form>
         <!-- 第二步 -->
         <el-form :model="contentForm" :rules="rules" ref="contentForm_1" label-width="100px">
           <el-row class="step2-cont" v-if="active === 1">
             <el-col :span="2" class="left">目标用户</el-col>
             <el-col :span="22" class="right">
               <el-row v-for="(item, index) in targetUsers" :key="index">
                 <el-select  v-model="item.groupId" @change="targetUser" placeholder="请选择目标用户" class="width-300">
                   <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id"></el-option>
                 </el-select>
                 <span class="icon" @click="removeGroup(index)">
                   <i class="el-icon-circle-close"></i>
                 </span>
               </el-row>
               <el-row>
                 <el-button class="width-300" icon="el-icon-plus"  @click="appendGroup">添加分组</el-button>
               </el-row>
             </el-col>
           </el-row>
         </el-form>
         <!-- 第三步 -->
         <el-form :model="contentForm" :rules="rules" ref="contentForm_2" label-width="100px">
           <el-row class="step3-cont" v-show="active === 2">
              <el-row class="top-rule">
                 <el-col :span="2" class="left">弹出规则</el-col>
                 <el-col :span="22" class="right">
                    <el-row class="radio-box">
                       <span><el-radio v-model="radio" label="1">基于用户浏览事件显示</el-radio></span>
                       <span><el-radio v-model="radio" label="2">基于用户事件触发显示</el-radio></span>
                    </el-row>
                    <el-row class="radio-cont">
                       <span class="tips-text">当用户浏览</span>
                       <el-row class="form-box">
                          <el-row class="input-box">
                             <el-input class="width-370" ></el-input>
                             <p class="p1">支持 * 通配符, 如: https://ea.analysys.cn/* , 则可匹配 https://ea.analysys.cn/ 下所有页面。</p>
                          </el-row>
                          <el-row class="selector-list">
                             <el-row class="selector-item">
                                <el-select class="width-175"  v-model="value" placeholder="请选择">
                                   <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                   </el-option>
                                </el-select>
                                <el-select class="width-120"  v-model="value" placeholder="请选择">
                                   <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                   </el-option>
                                </el-select>
                                <el-input class="width-120" ></el-input>
                                <i class="el-icon-circle-close"></i>
                             </el-row>
                          </el-row>
                          <el-row class="text-label">时，立即弹窗</el-row>
                       </el-row>
                    </el-row>
                 </el-col>
              </el-row>
              <el-row class="btm-rate">
                 <el-col :span="2" class="left">弹出频率</el-col>
                 <el-col :span="22" style="font-size: 12px;width: 75%;float: left">
                    <el-row style="border-bottom: 1px solid #f6f6f6;padding-bottom: 10px">
                       <el-row style="display: flex;margin-top: 8px">
                          <p>若用户<b style="color: #333333">点击</b>弹窗，下次触发相同条件时，弹窗将会</p>
                          <el-select class="width-100" v-model="this.clickWinAction"  placeholder="请选择" style="margin: 0" >
                             <el-option v-for="item in ejectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                       </el-row>
                       <el-row class="flex" v-show="this.clickWinAction === 2" style="margin-top: 8px">
                          <p>添加弹出次数上限：活动期间，最多向用户展示</p>
                          <el-input-number class="width-100"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <p>次</p>
                       </el-row>
                       <el-row class="flex" v-show="this.clickWinAction === 2 || this.clickWinAction === 3" style="margin-top: 8px">
                          <el-checkbox></el-checkbox>
                          <p>添加弹出时间间隔：点击弹窗后</p>
                          <el-input-number class="width-100" style="margin: 0 3px"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <el-input-number class="width-100"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <p>内不再弹出</p>
                       </el-row>
                    </el-row>
                    <el-row style="margin-top: 10px;border-bottom: 1px solid #f6f6f6;padding-bottom: 10px">
                       <el-row style="display: flex; margin-top: 8px">
                          <p>若用户<b style="color: #333333">关闭</b>弹窗，下次触发相同条件时，弹窗将会</p>
                          <el-select class="width-100" v-model='closeWinAction' prop="closeWinAction" placeholder="请选择" style="margin: 0" >
                             <el-option v-for="item in ejectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                       </el-row>
                       <el-row v-show="closeWinAction === 2" class="flex" style="margin-top: 8px">
                          <p>添加弹出次数上限：活动期间，最多向用户展示</p>
                          <el-input-number class="width-100"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <p>次</p>
                       </el-row>
                       <el-row v-show="closeWinAction === 2 || closeWinAction === 3" class="flex" style="margin-top: 8px">
                          <el-checkbox></el-checkbox>
                          <p>添加弹出时间间隔：点击弹窗后</p>
                          <el-input-number class="width-100" style="margin: 0 3px"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <el-input-number class="width-100"  :min="1" :max="10" label="描述文字"></el-input-number>
                          <p>内不再弹出</p>
                       </el-row>
                    </el-row>
                 </el-col>
              </el-row>
           </el-row>
         </el-form>
         <!-- 第四步 -->
         <el-form :model="contentForm" :rules="rules" ref="contentForm_3" label-width="100px">
            <el-row class="step4-cont" v-show="active === 3">
               <!-- 模板列表 -->
               <el-row class="template-list" v-show="!showSetTemp">
                  <el-row class="template-item" @click.native="showSetTemp = true">
                     <el-row class="top-img"><img src="@/assets/template_02.f896d5c.png" alt=""></el-row>
                     <el-row class="btm-tit">文本+图片</el-row>
                     <el-row class="hover-item">
                        <i class="el-icon-circle-plus"></i>
                        <p>点击创建弹窗</p>
                     </el-row>
                  </el-row>
               </el-row>
               <!-- 模板编辑 -->
              <!-- 模板编辑 -->
              <el-row class="template-edit" v-show="showSetTemp">
                <el-row class="temp-left" v-show="edmitshow">
                  <el-row class="title"><b>整体设置</b><a>更换模板</a></el-row>
                  <el-row class="scroll-box">
                    <el-row  class="m-bottom-5">弹窗大小</el-row>
                    <el-row type='flex' ><el-input  v-model="dgwidth"  ><i slot="suffix" class="uniticon" >宽</i></el-input><el-input style="margin-left: 10px;"   v-model="dgheight"  ><i class="uniticon" slot="suffix" >高</i></el-input>
                    </el-row>
                  </el-row>
                  <el-row class="scroll-box">
                    <el-row  class="m-bottom-5">内容元素</el-row>
                    <el-row ><div class="cont-box" @click="addcontent(i.id)" v-for="i in contentlist" :key="i.id">{{i.name}}  <div class="btn-box"><el-button type="text" icon="el-icon-delete" circle></el-button></div></div>
                    </el-row>
                  </el-row>
                  <el-row class="scroll-box">
                    <el-row class="button-box" ><el-button  >上一步</el-button>
                    <el-button class="bg-gradient" type="primary">下一步</el-button>
                    </el-row>
                  </el-row>
                </el-row>

                 <!--文本输入框-->
                <el-row class="temp-left" v-if="!edmitshow && edmittype != 3 && edmittype != 4">
                  <el-row class="title"><label @click="edmitshow = !edmitshow">{{comeback}}</label><b style="margin-left: 30px">文本</b></el-row>
                  <el-row class="scroll-box">
                    <el-row  class="m-bottom-5">文本内容</el-row>
                    <el-row v-if="edmittype ==1"><el-input type="textarea" show-word-limit maxlength="150" v-model="title"></el-input>
                    </el-row>
                    <el-row v-if="edmittype ==2"><el-input type="textarea" show-word-limit maxlength="150" v-model="contenttext"></el-input>
                    </el-row>
                  </el-row>
                </el-row>


                 <!--图片-->
                <el-row class="temp-left" v-if="!edmitshow && edmittype == 3">
                  <el-row class="title"><label @click="edmitshow = !edmitshow">{{comeback}}</label><b style="margin-left: 30px">图片</b></el-row>
                  <el-row class="scroll-box">
                    <el-row  class="m-bottom-5">图片大小</el-row>
                    <el-row type='flex' ><el-input  v-model="dgimgwidth"  ><i slot="suffix" class="uniticon" >宽</i></el-input><el-input style="margin-left: 10px;"   v-model="dgimgheight"  ><i class="uniticon" slot="suffix" >高</i></el-input>
                    </el-row>
                    <el-row class="m-bottom-5" style="margin-top: 20px">上传图片</el-row>
                    <el-row>
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :limit="1"
                        ref="upload"
                        :show-file-list="false"
                        :on-change="handleChange"
                        :auto-upload="false"
                        :multiple="false"
                        style="z-index: 999"
                    >
                      <i  class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    </el-row>
                  </el-row>
                </el-row>

                <!--按钮-->
                <el-row class="temp-left" v-if="!edmitshow && edmittype == 4">
                  <el-row class="title"><label @click="edmitshow = !edmitshow">{{comeback}}</label><b style="margin-left: 30px">按钮</b></el-row>
                  <el-row class="scroll-box">
                    <el-row  class="m-bottom-5">文字</el-row>
                    <el-row><el-input type="text"  v-model="btntext"></el-input>
                    </el-row>
                    <el-row style="margin-top: 10px" class="m-bottom-5">按钮触发</el-row>
                    <el-row >
                      <el-radio v-model="btn_radio" label="1">关闭弹窗</el-radio>
                    </el-row>

                  </el-row>
                </el-row>

               <!--弹出框-->
                <el-row class="temp-right" v-bind:style="{ minHeight:dgheight  + 'px', minWidth: dgwidth +  'px' }">
                  <el-row class="dagbox" v-bind:style="{ minHeight:dgheight  + 'px',minWidth: dgwidth +  'px'}">
                   <!--标题-->
                    <el-row class="dahead"  @click.native="addcontent(1)">
                      {{title}}
                    </el-row>
                    <!--内容-->
                    <el-row class="dacontent" @click.native="addcontent(2)">
                    {{contenttext}}
                  </el-row>
                    <!--图片-->
                    <el-row class="daimg"  @click.native="addcontent(3)">
                      <img src="../../../../../src/assets/1111.jpg">
                    </el-row>
                    <!--按钮-->
                    <el-row class="daimg"  @click.native="addcontent(4)">
                      <el-button  type="primary">{{btntext}}</el-button>
                    </el-row>
                  </el-row>
                </el-row>
              </el-row>
            </el-row>
         </el-form>
         <!-- 第五步 -->
         <el-form :model="contentForm" :rules="rules" ref="contentForm_4" label-width="100px">
           <el-row class="step5-cont" v-if="active === 4">
             <el-col :span="10" class="right-img">
               <el-row class="img">
                 <el-row class="dynamic-img">
                   <img alt />
                 </el-row>
                 <img src="@/assets/create_applet_img.jpg" alt />
               </el-row>
             </el-col>
             <el-col :span="14" class="right-text">
               <el-row>
                 活动名称:
                 <span>东山风动石</span>
               </el-row>
               <el-row>
                 目标用户:
                 <span>所有用户</span>
               </el-row>
               <el-row>
                 开始时间:
                 <span>2021-03-09 00:00:00</span>
               </el-row>
               <el-row>
                 结束时间:
                 <span>2021-03-09 23:59:59</span>
               </el-row>
               <el-row>
                 推送规则:
                 <span>活动期间一直显示</span>
               </el-row>
               <el-row>
                 插图链接:
                 <span>https://123eguan.cn-bj.ufileos.com/123_7f3ed4e0-b1ad-4390-a27e-37929990c638.jpg</span>
               </el-row>
               <el-row>
                 消息内容:
                 <span>活动详情</span>
               </el-row>
               <el-row>
                 <el-button  @click="active--">上一步</el-button>
                 <el-button  type="primary" @click="handleSave">开始</el-button>
                 <el-button >发送到测试设备</el-button>
               </el-row>
             </el-col>
           </el-row>
         </el-form>
         <!-- 按钮  -->
         <el-row class="next-btn m-top-15" v-show="active < 3">
           <el-button  type="primary" v-show="active !== 0" @click="active--">上一步</el-button>
           <el-button class="m-left-30 bg-gradient" type="primary" @click="nextStep">下一步</el-button>
         </el-row>
       </el-row>
     </el-row>
  </section>
</template>

<script>
  import { urlObj } from '@/api/interface'
  import { operate } from  '@/api/interface/smtech'
  import { label } from "@/api/interface/data";
  import common from '@/common/js/common'
  import { mapState } from 'vuex'
  export default {
    watch: {
      hotelInfo: {
          handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
              this.hotelId = this.hotelInfo.id
            }
          },
      },
      'contentForm.isConvert': {
         handler(newV, oldV) {
           if (!newV) {
             this.contentForm.days = null
             this.contentForm.targetId = '' // 转化目标
           }
         },
       },
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
     // components: {UE},
     data() {
       return {
          radio: '1',
          accountType: '',
          showSetTemp: false,
         edmitshow:true,
         comeback:'< 返回',
         btn_radio:'1',
         edmittype:"",
         title:'标题',
         contenttext:'内容',
         btntext:'按钮',
         file:'',
          Android:'',
         iOS:'',
         content:'',
         closeWinAction: 1, // 关闭弹窗频率
         dgwidth:'420',
         dgheight:'300',
         dgimgheight:'300',
         dgimgwidth:'300',
         padding:30,
         contentlist:[
           {id:1,name:'文本'},
           {id:2,name:'文本'},
           {id:3,name:'图片'},
           {id:4,name:'按钮'}
         ],
         initHotelId: '',
         clickWinAction: 1, // 点击弹窗频率
         active: 0,
         targetList: [], // 转化目标列表
         /* 第二步 */
         targetUsers: [{}], // 转化用户列表
         groupList: [], // 群组列表
         //用户事件触发显示时机
         showTypeList: [
           { id: 1, name: '立即显示弹窗' },
           { id: 2, name: '在指定页面显示弹窗' },
         ],
         Url:'',
         eventPropList: [
           // 弹出列表
           { id: 1, name: '等于' },
           { id: 2, name: '不等于' },
           { id: 3, name: '包含' },
           { id: 4, name: '不包含' },
           { id: 5, name: '有值' },
           { id: 6, name: '无值' },
         ],
         //事件属性等于
         ejectList: [
           // 弹出列表
           { id: 1, name: '不再弹出' },
           { id: 2, name: '限制次数内弹出' },
           { id: 3, name: '每次弹出' },
         ],
         value:'',

         options:[
           { lable: 1, value: '不再弹出' },
         ],
         eventTypeList: [], // 事件属性列表
         //表格数据
         contentForm: {
           /* 第一步 */
           eventName: '', // 活动名称
           timeCycle: [], // 周期时间段
           isConvert: false, // 转化跟踪
           days: null, // 转化周期
           targetId: '', // 转化目标
           /* 第三步 */
           pushRule: 'BROWSE_EVENTS', // 推送规则 TRIGGER_EVENT
           appletEvent: {
             url: '',
             eventList: [{ eventProp: 1 }], // 事件属性
           },
           triggerEvent: {
             eventType: '',
             showType: '',
           },
           //触发弹窗Url
           triggerId: '', //
           clickShowCount: 0,
           closeShowCount: 0,
           limtClickNum: 0, //间隔时间
           limtClick: '', //天/日
           limtClose: '', //天/日
           limtCloseNum: 0, //间隔时间
           /* 第四步 */
           content: '',
           file: null,
           mediaPath: '',
           isWaitSend: 'NO', // 等待发送
           tiggerConditionId: '', // 触发事件
           tiggerConditionValue: '', // 触发条件值
         },
         rules: {
           eventName: [
             { required: true, message: '请输入活动名称', trigger: 'blur' },
           ],
           timeCycle: [
             { required: true, message: '请输入活动日期', trigger: 'blur' },
           ],
           days: [
             { required: true, message: '请输入转化周期', trigger: 'blur' },
             {
               type: 'number',
               min: 0,
               message: '请输入大于0的正整数',
               trigger: ['change', 'blur'],
             },
           ],
           pushRule: [{ required: true, message: '请输入路径', trigger: 'blur' }],
         },
       }
     },
     mounted() {
       this.initHotelId = this.hotelInfo.id
        this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
       this.getGroupList()
       this.getTargetList('CONVERSION_EVENTS')
       this.getEventTypeList()
     },

     methods: {
        setTemplate(){

        },
       targetUser(u) {
         let i = 0
         let length = this.targetUsers.length
         for (var j = 0; j < length - 1; j++) {
           if (this.targetUsers[j].groupId == u) {
             i++
             if (i == 1) {
               this.$alert('该用户已被选，请选择其他用户', '', {
                 confirmButtonText: this.confirm,
                 type: 'error',
               })
               this.removeGroup(length - 1)
               this.appendGroup()
               break
             }
           }
         }
       },
       //图片上传
       handleChange(file) {
         this.$refs.upload.clearFiles();
         this.file = file.raw
         const url = urlObj.uploadDetailFile;
         let param = new FormData();
         param.append("file", this.file); // 文件
         param.append('bizId','default')
         param.append('module','event')
         param.append('menu','distributor')
         param.append('func','image')
         param.append('category','image')
         param.append('isThumb',true)
         this.$axios.post(url, param, 'file')
             .then((res) => {
               console.log(res);
               this.uploadpictures = res.records
               let uploadpictureb = []
               uploadpictureb.push(this.uploadpictures)
               uploadpictureb.forEach(item=>{
                 this.imageUrl = window.getResourcesHost()+item.location+'/'+item.formatName
                 console.log(this.imageUrl)
               })
             });
       },
       // 处理保存
       handleSave() {
         let groupIds = []
         this.targetUsers.forEach((item) => groupIds.push(item.groupId))
         const url = operate.createActivity
         let param = new FormData()
         param.append('file', this.file) // 文件
         param.append('module', 'system') // 所在模块
         param.append('menu', 'company') // 所属菜单
         param.append('func', 'logo') // 图片功能
         param.append('category', 'logo') // 图片分类
         param.append('isThumb', true) // 是否开启缩略图
         param.append('hotelId', this.hotelId || this.initHotelId)
         param.append('companyId', this.companyId)
         param.append('eventName', this.eventName)
         param.append('beginDate', this.timeCycle[0])
         param.append('endDate', this.timeCycle[1])
         param.append('isConvert', this.isConvert)
         param.append('targetId', this.targetId)
         param.append('days', this.days)
         param.append('groupIds', groupIds.join())
         param.append('pushRule', this.pushRule)
         param.append('platform', 'INFORMATION_FLOW_ADVERTISING')
         param.append('state', 'IN_PROGRESS')
         param.append('eventWechatDTO.type', 'ADVERTISEMENT_IMG_POPUP')
         param.append('eventWechatDTO.content', this.content)
         param.append('eventWechatDTO.pagePath', this.pagePath)
         if (this.act === 'edit') {
           param.append('id', this.id)
         }
         common.post(url, param, 'file').then((res) => {
             if (res.success) {
               this.$message({
                 showClose: true,
                 message: '创建成功！',
                 type: 'success',
               })
               this.goBack()
             }
           })
       },
       /* 第一步 */
       // 获取转化目标列表
       getTargetList(category) {
         const url = urlObj.metadataList
         const param = { category }
         this.$axios.post(url, param).then((res) => {
           if (res.success) this.targetList = res.records
         })
       },
       /* 第二步 */
       // 追加转化目标列
       appendGroup() {
         this.targetUsers.push({})
       },
       // 移除转化目标
       removeGroup(index) {
         let length = this.targetUsers.length
         if (length > 1) this.targetUsers.splice(index, 1)
       },
       // 获取群组列表
       getGroupList() {
         const url = label.tagGroup
         const param = {
           hotelId: this.hotelId,
           groupSource: 'WECHAT_OFFICIAL',
           limit: 15,
           page: 1,
         }
         this.$axios.post(url, param).then((res) => {
           if (res.success) {
             this.groupList = res.records
           }
         })
       },
       // 获取事件属性列表
       getEventTypeList() {
         const url = urlObj.metadataList
         const param = {
           category: this.contentForm.pushRule,
         }
         this.$axios.post(url, param).then((res) => {
           if (res.success) {
             this.eventTypeList = res.records
           }
         })
       },
       /* 第三步 */
       // 追加事件
       appendEvent() {
         this.contentForm.appletEvent.eventList.push({})
       },
       // 移除事件
       removeEvent(index) {
         this.contentForm.appletEvent.eventList.splice(index, 1)
       },
       /* 第三步 */
       // 改变启动小程序事件
       changeWaitSend(val) {
         if (val === 1) this.getTargetList('TRIGGER_EVENT')
       },
       /* 第四步 */
       changeUpload(file) {
         // console.log(file)
         this.mediaPath = file.url
         this.file = file.raw
       },
       // 返回上页
       goBack() {
         this.$router.go(-1)
       },
       addcontent(val){
         this.edmitshow = false
         this.edmittype = val
       },
       // 下一步
       nextStep() {
         if (!this.contentForm.eventName) {
           this.$alert('活动名称不能为空！', '', {
             confirmButtonText: this.confirm,
             type: 'error',
           })
           return
         }
         if (this.contentForm.timeCycle.length === 0) {
           this.$alert('活动时间不能为空！', '', {
             confirmButtonText: this.confirm,
             type: 'error',
           })
           return
         }
         if (this.contentForm.isConvert && this.contentForm.days <= 0) {
           this.$alert('活动周期不能为空', '', {
             confirmButtonText: this.confirm,
             type: 'error',
           })
           return
         }
         // 第二步
         if (this.active === 1) {
           if (this.targetUsers.length === 0) {
             this.$alert('目标用户不能为空！', '', {
               confirmButtonText: this.confirm,
               type: 'error',
             })
             return
           }
         }
         // 第四步
         if (this.active === 3) {
           if (!this.mediaPath) {
             this.$alert('背景图片不能为空！', '', {
               confirmButtonText: this.confirm,
               type: 'error',
             })
             return
           }
         }
         this.$refs['contentForm_' + this.active].validate((valid) => {
           if (valid) {
             this.active++
           } else {
             this.$alert('数据验证不通过！', '', {
               confirmButtonText: this.confirm,
               type: 'error',
             })
             return false
           }
         })
       },
     },
   }
</script>

<style lang="scss" scoped>
.title-bar{
   line-height: 60px; padding: 0 20px; background-color: #f7f7f8;
   h2 { float: left;font-size: 18px;font-weight: normal }
   .btn{ float: right }
}
.step-box{
   border-bottom: 1px solid #e4e4e4;
   .step-wrapper{
      padding: 10px 0 10px 20px; width: 800px;
      /deep/ .el-step__title{ font-size: 12px }
   }
}
.content{
   font-size: 13px; padding: 0;
   .step1-cont{
      width: 700px; padding: 30px 0 0 40px;
      .input-box{
         line-height: 28px; margin-bottom: 20px;
      }
   }
   .step2-cont{
      width: 700px; padding: 30px 0 0 40px;
      .left { line-height: 28px }
      .right {
         .el-row {
            margin: 0 0 15px 10px;
            .el-select{ margin-bottom: 0 }
            .icon{ font-size: 18px; color: #999999; cursor: pointer }
         }
      }
   }
   .step3-cont{
      padding: 30px 0 0 40px; width: 900px; font-size: 12px;
      .el-col-22{ padding: 0 10px 0 0 }
      .top-rule{
         .right{
            .radio-box{
               margin-bottom: 30px;
               span{ padding: 6px 10px; border: 1px #e4e4e4 solid; margin: 0 10px }
               /deep/ .el-radio__label{ font-size: 12px }
            }
            .radio-cont{
               overflow: hidden;
               .tips-text{ float: left; line-height: 28px; margin-right: 6px }
               .form-box{
                  float: left; line-height: 22px; color: #999999;
                  .p1 {
                     width: 370px; padding: 8px 10px; border: solid 1px #e4e4e4;
                     margin: 10px 0 6px 0; border-radius: 4px;
                  }
                  .color { color: #f14343 }
                  .selector-list{
                     margin-top: 20px; font-size: 18px;
                     .selector-item { margin-bottom: 10px }
                     i { margin-left: 6px }
                  }
                  .text-label { text-align: center }
               }
            }
         }
      }
      .btm-rate{
         padding-bottom: 1rem; margin-top: 1rem;line-height: 28px;color: #999
      }
   }
   .step4-cont{
      .template-list{
         padding: 30px 0 40px 40px;
         .template-item:hover{ .hover-item{ display: block } }
         .template-item{
            border: 1px solid #ddd; width: 204px; border-radius: 4px;
            .top-img{
               background: rgba(19,28,66,0.1); padding: 20px;
               img{ width: 100% }
            }
            .btm-tit{ text-align: center; padding: 20px 0; font-weight: bold }
            .hover-item{
               position: absolute; top: 0; left: 0; width: 100%; height: 100%;
               background: #FFFFFF; border-radius: 4px; text-align: center;
               padding: 90px 0 0 0; display: none; color: #688FF4; cursor: pointer;
               .el-icon-circle-plus{ font-size: 26px; margin-bottom: 20px }
            }
         }
      }
      .template-edit{
         .temp-left{
            max-width: 240px; background: #f7f7f8; padding: 20px 0 0 0;
            .title{
               padding: 8px 20px 12px 20px; border-bottom: solid 1px #e8e8e8;
               a { float: right; color: #679bff; cursor: pointer }
            }
            .scroll-box{
               padding: 20px 20px 0; max-height: 450px; overflow-y: auto;
               .pos-bd{
                  border: 1px solid #dcdcdc; border-radius: 2px;
               }
            }
         }
      }
   }
   .step5-cont{
      .right-text{
         padding: 6% 10%; line-height: 40px; color: #999999;
         span{ color: #333333; margin-left: 10px }
      }
   }
   .next-btn { margin: 0 0 40px 40px }
}
.uniticon{
  font-style:normal;
  margin: 5px;
  display: inline-block;
}
.cont-box{
  height: 39px;
  background: hsla(0,0%,100%,.8);
  margin-bottom: 10px;
  line-height: 39px;
  padding: 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  border: 1px solid hsla(0,0%,100%,.8);
  position: relative;
  cursor: pointer;
}
.cont-box .btn-box{
  display: none;
}
//.cont-box:hover  .btn-box{
//  display: inline-block;
//}
//.btn-box{
//  position: relative;
//  right: -120px;
//}
//.btn-box:hover{
//  color: #087AFF;
//}
.button-box{
  text-align: center;
  padding: 0 8px;
}
.temp-right{
  background: #c0c4cc;
  width: 65%;
  max-width: 100%;
  position: absolute;
  top: 28px;
  left: 280px;
  text-align: center;
  overflow: auto;
}
.dagbox{
  margin: 30px;
  display: inline-block;
  padding: 20px 0;
  background: #fff;
  border-radius: 5px;
  //width: 100%;
}
.dahead{
  height: 30px;
  line-height: 30px;
}
.dacontent{
  text-align: left;
  text-indent: 2em;
}
.dagbox>:before{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  background: rgba(255,240,222,.5);
  z-index: 1;
  border: 1px solid #f5c991;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
}
.dagbox>:hover:before{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 1;
  background: rgba(255,240,222,.5);
  z-index: 1;
  border: 1px solid #f5c991;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow   : inset 0 0 3px rgba(0, 0, 0, 0.2);
  background   : #535353;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
.upload-box{
  text-align: center;
  border: 1px solid #e4e4e4;
  background: rgba(255,255,255,0.8);
  width: 200px;
  cursor: pointer;
  margin-bottom: 20px;
}
.upload-box span{
  position: absolute;
  top: 12px;
  left: 70px;
}
@media screen and (max-width: 1366px) {
   .main { width: 1500px }
}
</style>

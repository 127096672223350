import { render, staticRenderFns } from "./createWeb.vue?vue&type=template&id=a53632d6&scoped=true&"
import script from "./createWeb.vue?vue&type=script&lang=js&"
export * from "./createWeb.vue?vue&type=script&lang=js&"
import style0 from "./createWeb.vue?vue&type=style&index=0&id=a53632d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a53632d6",
  null
  
)

export default component.exports